<template>
    <div class="supplier">
        <el-page-header @back="$router.go(-1)" content="企业信息"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="heard f-c-c">
                    <span>{{ enterpriseName }}</span>
                </div>
                <div class="w-550 f-c-c">
                    <el-form class="transferadminForm" :rules="transferadminRules" style="width: 550px;"
                        :model="transferadminForm" label-width="100px" label-position="right">
                        <el-form-item prop="phone" label="当前手机号">
                            <PhoneInput :modelValue="transferadminForm.phone" @getPhoneNumber="getPhoneNumber"
                                @clearPhone="clearPhone" :disableValue="true">
                            </PhoneInput>
                        </el-form-item>
                        <el-form-item prop="code" label="验证码">
                            <GetCode :type="'transferadminForm'"></GetCode>
                        </el-form-item>
                        <el-form-item prop="targetUserId" label="选择新管理员">
                            <div v-if="!transferadminForm.targetUserId" class="f-c-b targetUserId"
                                @click="isPreviewVisible = true;">
                                <div style="color: #C0C4CC;">请选择人员</div>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                            <div v-else class="f-c h-60 selectEmployees" @click="isPreviewVisible = true;">
                                <img class="userAvatarUrl" :src="selectEmployees.userAvatarUrl" alt="">
                                <div class="fs-24 col-33">{{ selectEmployees.ecardName }}</div>
                            </div>
                        </el-form-item>
                        <div class="f-c-c mt-100">
                            <div class="login-submit" @click="sure">确定</div>
                            <div class="cancel" @click="$router.go(-1)">取消</div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="isPreviewVisible" width="1080px" @close="dialogClose()">
            <div class="f-cl-c">
                <div>
                    <!-- 搜索 -->
                    <div class="f-c-c feature">
                        <div class="search_input">
                            <input type="text" placeholder="请输入员工名称搜索" v-model.trim="ecardName" @keyup.enter="search"
                                maxlength="10" />
                            <el-button class="search_icon" @click="search" icon="el-icon-search"></el-button>
                        </div>
                    </div>
                </div>
                <div class="list f-w-b" v-if="UserList && UserList.length">
                    <div class="item f-c" v-for="(item, index) of UserList" :key="index"
                        :style="{ marginRight: (index % 2 == 0) ? '20px' : '0' }" @click="sureUser(item)">
                        <img class="userAvatarUrl" :src="item.userAvatarUrl" alt="">
                        <div class="f-cl-b textBox">
                            <span>{{ item.ecardName }}</span>
                            <span>联系方式：{{ item.ecardPhone }}</span>
                            <span>所属部门：{{ item.ecardNote ? item.ecardNote : '未设置' }}</span>
                        </div>
                        <div class="BusinessOwner" v-if="item.dcPermissionFlag == 'Y'">企业所有者</div>
                    </div>
                </div>
                <el-empty v-else :description="ecardName ? '搜索不到该员工！' : '快去添加员工吧！'"></el-empty>
            </div>
            <Pagination v-if="UserList && UserList.length" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
                :layout="layout" @pagination="fetchData" />
        </el-dialog>
    </div>
</template>
<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { transferadminRules } from "@/utils/FormRules";
export default {
    name: 'SupplierInfo',
    mixins: [PaginationRetention],
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    data() {
        return {
            enterpriseName: '',
            // 短信表单
            transferadminForm: {
                phone: "",
                code: "",
                targetUserId: ''
            },
            // 短信规则
            transferadminRules,
            isPreviewVisible: false,
            UserList: [],
            ecardName: '',
            userPermission: {
                "a_m": "",//人员权限管理
                "c_m": "",//客户管理
                "e_i": "",//企业信息
                "f_s": "",//财务统计
                "i_m": "",//库存管理
                "o_m": "",//订单管理
                "p_c": "",//工作牌
                "p_l": "",//产品库
                "p_m": ""//人员管理
            },
            selectEmployees: {},
        }
    },
    created() {
        this.findByIdEnterpriseInfo();
        this.transferadminForm.phone = this.$getStorage('userPhone');

    },
    methods: {
        getDate() {
            this.enterpriseUserList(this.paging, this.pagingSize)
        },// 企业用户列表接口
        enterpriseUserList(page = this.page, size = this.size) {
            let that = this,
                { ecardName, userPermission } = that;
            that.$http.enterpriseUserList({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                ownerStatus: 'Y',
                page,
                size,
                ecardName,
                userPermission
            }).then(res => {
                this.UserList = res.data.records
                this.total = res.data.total
                this.currentPage = res.data.current
            })
        },
        search() {
            this.page = 1;
            this.UserList = []
            this.enterpriseUserList()
        },
        // 根据企业id获取企业列表详情接口 
        findByIdEnterpriseInfo() {
            let that = this, data = {
                enterpriseId: this.$getStorage('enterpriseStateInfo').enterpriseId,
                source: "pc",
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            };
            that.$http.findByIdEnterpriseInfo(data).then(res => {
                let { enterpriseName, eimage1 } = res.data;
                that.enterpriseName = enterpriseName;
                // that.enterpriseId = enterpriseId;
                that.enterPriseImage = eimage1;
            });
        },
        // 获取手机号
        getPhoneNumber(data) {
            let { value } = data;
            this.transferadminForm.phone = value;
        },
        // 清空手机号
        clearPhone() {
            this.transferadminForm.phone = '';
        },
        dialogClose() {
            this.isPreviewVisible = false;
        },
        sureUser(item) {
            this.transferadminForm.targetUserId = item.userId;
            this.isPreviewVisible = false;
            this.selectEmployees = item;
        },
        sure() {
            let that = this,
                codeValue = that.$getStorage('codeValue');
            that.$confirm('确定转让？', '提示').then(() => {
                that.$http.reviseEnterpriseAdministrator({
                    userId: parseInt(that.$store.state.userId),
                    code: codeValue.code,
                    targetUserId: that.transferadminForm.targetUserId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, "success");
                        that.$removeStorage('codeValue');
                        // that.$removeStorage('enterpriseStateInfo');
                        // that.findEnterpriseAuditStateAndInfo();
                        that.$router.go(-1);
                    }

                })
            }).catch(() => { })

        },
        // findEnterpriseAuditStateAndInfo() {
        //     let that = this;
        //     that.$http.findEnterpriseAuditStateAndInfo({
        //         applicantId: parseInt(that.$store.state.userId),
        //         token: that.$getStorage('token')
        //     }).then(res => {
        //         that.$setStorage('enterpriseStateInfo', res.data)
        //     })
        // },
    },

}
</script>

<style lang="less" scoped>
.supplier {
    width: 1200px;
    // margin: auto;

    .main {
        width: 980px;

        .heard {
            margin-bottom: 50px;
            padding: 0 20px;
            height: 100px;
            background: url('../../../assets/supply/bannerSupplyIndex.png') no-repeat;


            span {
                font-weight: 500;
                font-size: 30px;
                color: #FFFFFF;
            }
        }

        .transferadminForm {
            .targetUserId {
                cursor: pointer;
                width: 420px;
                height: 40px;
                padding: 0 15px;
                border: 1px solid #DCDFE6;
                border-radius: 4px;
            }

            .selectEmployees {
                cursor: pointer;

                .userAvatarUrl {
                    border-radius: 50%;
                    height: 54px;
                    width: 54px;
                    margin-right: 23px;
                }
            }

            .login-submit {
                width: 160px !important;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 10px;
                background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                color: #fff;
                font-size: 16px;
                font-family: 'Microsoft YaHei';
                border: none;
                cursor: pointer;
            }

            .cancel {
                margin-left: 60px;
                width: 160px !important;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 10px;
                background: #EFF4FA;
                color: #0363FA;
                font-size: 16px;
                font-family: 'Microsoft YaHei';
                border: none;
                cursor: pointer;
            }

        }
    }

    .feature {
        margin-bottom: 20px;

        .search_input {
            input {
                background-color: #FFF;
                border-radius: 4px 0 0 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 50px;
                line-height: 50px;
                outline: 0;
                padding: 0 20px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 720px;
                border-right: none;
                font-size: 16px;
            }

            .search_icon {
                width: 80px;
                height: 50px;
                border-top-right-radius: 4px !important;
                border-top-left-radius: 0px !important;
                border-bottom-right-radius: 4px !important;
                border-bottom-left-radius: 0px !important;
                color: #FFFFFF;
                background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            }
        }

    }

    .list {
        width: 920px;

        .item {
            width: 400px;
            height: 100px;
            background: #F8F8F8;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 20px;
            position: relative;
            cursor: pointer;

            .userAvatarUrl {
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }

            .BusinessOwner {
                width: 100px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                color: #fff;
                position: absolute;
                right: 0;
                background: url('../../../assets/supply/BusinessOwner.png') no-repeat;
            }

            .textBox {
                height: 80px;
                width: 245px;
                margin-left: 30px;

                .name {
                    font-size: 20px;
                    font-weight: 400;
                    color: #000000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 14px;
                }

                .type {
                    font-size: 16px;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 24px;
                }
            }

            .checkbtn {
                width: 80px;
                height: 40px;
                border: 1px solid #1785F6;
                border-radius: 5px;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #1785F6;
                cursor: pointer;
            }
        }
    }
}
</style>